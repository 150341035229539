<template lang="pug">
.input
  input(
    v-bind="$attrs",
    :id="id",
    :style="{ width: width, textAlign: textAlign }",
    :class="{ yellow: attrs.yellow }",
    @input="input",
    :name="name",
    v-model="model",
    :checked="model == attrs.value",
    ref="inputDom",
    :inputmode="attrs.type == 'password' || attrs.type == 'tel' ? 'numeric' : 'text'",
    :pattern="attrs.pattern"
  )
  label(:for="id")
    slot
</template>
<script setup name="inputComponent">
import { randomString } from "@/utils/utils";
import { computed, inject, ref, useAttrs } from "vue";
import { useField } from "vee-validate";
import { get } from "vant/lib/utils";
const id = ref(randomString(5));
const emit = defineEmits(["update:value", "input"]);
const attrs = useAttrs();
const inputDom = ref();
const props = defineProps({
  width: {
    type: String,
    default: "100%"
  },
  disabled: Boolean,
  textAlign: { type: String, default: "left" },
  name: String
});

const radioGroup = inject("radioGroup", null);
const isGroup = computed(() => {
  return !!radioGroup;
});
const name = computed(() => {
  if (isGroup.value) {
    return radioGroup.$props.name;
  } else {
    return attrs.name;
  }
});
const model = computed({
  get() {
    return isGroup.value ? radioGroup.value : attrs.modelValue;
  },
  set(value) {
    isGroup.value ? radioGroup.$emit("input", value) : emit("input", value);
  }
});
const input = (e) => {
  if (isGroup.value) {
    radioGroup.$emit("update:value", e.target.value);
    radioGroup.$emit("change", e.target.value);
  } else {
    emit("update:value", e.target.value);
  }
};
const focus = () => {
  inputDom.value.focus();
};
const blur = () => {
  inputDom.value.blur();
};
const isFouces = computed(() => {
  return document.activeElement === inputDom.value;
});
const type = computed(() => {
  if (attrs.type == "password") {
    console.log(isFouces.value);
    return isFouces.value ? "text" : "password";
  } else {
    return attrs.type || "input";
  }
});
defineExpose({
  focus,
  blur
});
</script>

<style lang="scss" scoped>
input {
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(239 239 239);
  padding: 10px 0;
  width: 100%;
  border-radius: 0;
  &:focus {
    border-bottom: 2px solid #ffc03c;
  }
  &[type="radio"],
  &[type="checkbox"] {
    display: none;
    + label {
      display: flex;
      flex-direction: row;
      &:not(.yellow) {
        &::before {
          background: url(@/assets/imgs/radio.png) left center no-repeat;
          background-size: contain;
          width: 28px;
          height: 28px;
          display: block;
          content: "";
        }
      }
      // padding-left: 35px;
      // display: block;
      line-height: 28px;
      font-size: $big;
    }
    &.yellow {
      + label {
        &::before {
          background-size: 20px 20px !important;
        }
      }
    }
    &:checked {
      + label {
        &:not(.yellow) {
          &::before {
            background: url(@/assets/imgs/radiochecked.png) left center
              no-repeat;
            background-size: contain;
          }
        }
      }
      &.yellow {
        + label {
          &::before {
            background: url(@/assets/imgs/radiocheckedy.png) left center
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
  &[type="password"],
  &[real-type="password"] {
    -webkit-text-security: disc;
  }
}
</style>
