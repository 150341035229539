import { login } from "@/apis/login/index";
import request from "@/utils/request";
import liff from "@line/liff";
import store from "@/store";
import { showDialog } from "vant";

let isLogining = false;
async function loginAction() {
  if (!localStorage.getItem(store.state.user.tokenKey)) {
    if (isLogining) return Promise.reject("already logining");
    isLogining = true;
    const { accessToken } = await login({
      lineToken: await getLiffAccessToken()
    });
    localStorage.setItem(store.state.user.tokenKey, accessToken);
    request.defaults.headers.accessToken = accessToken;
    await store.dispatch("user/getUserInfo");
    isLogining = false;
    return Promise.resolve();
  } else {
    return Promise.resolve();
  }
}

async function getLiffAccessToken() {
  for (let i = 0; i < 3; i++) {
    let res = await liff.getAccessToken();
    if (res) {
      return Promise.resolve(res);
    }
  }
  showDialog({
    message: "系統異常，請與客服聯繫",
    confirmButtonColor: "#EF0000",
    confirmButtonText: "確定"
  });
  return Promise.reject("liff accesstoken 獲取失敗");
}

export { loginAction, getLiffAccessToken };
