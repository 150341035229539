<template lang="pug">
.radio-group
  slot(:currentValue="props.value")
</template>

<script setup name="radioGroup">
import { getCurrentInstance, provide } from "vue";
const { proxy } = getCurrentInstance();
const emits = defineEmits(["change", "update:value", "input"]);
provide("radioGroup", proxy);
const props = defineProps({
  name: {
    type: String,
    default: ""
  },
  value: {
    default: null
  }
});
</script>

<style lang="scss" scoped></style>
