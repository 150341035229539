let loadingCount = 0;

const div = document.createElement("div");
/**
 * 顯示loading框
 *
 * @export
 */
export function showLoading() {
  // if (loadingCount > 0) return;
  div.setAttribute("class", "loading");
  div.style.background = "rgba(0,0,0,0.6)";
  div.style.width = "100%";
  div.style.height = "100%";
  div.style.position = "fixed";
  div.style.top = "0";
  div.style.left = "0";
  div.style.zIndex = "1500";
  const loadingBox = document.createElement("div");
  loadingBox.style.width = "130px";
  loadingBox.style.height = "130px";
  loadingBox.style.position = "absolute";
  loadingBox.style.left = "50%";
  loadingBox.style.top = "50%";
  loadingBox.style.transform = "translate(-50%, -50%)";
  loadingBox.style.background = "white";
  loadingBox.style.borderRadius = "50%";
  loadingBox.style.boxSizing = "border-box";
  loadingBox.style.overflow = "hidden";
  loadingBox.style.padding = "10px";
  const loadingImage = document.createElement("img");
  loadingImage.src = new URL(
    "../assets/imgs/ic_loading.gif",
    import.meta.url
  ).href;
  loadingImage.style.width = "100%";
  loadingBox.appendChild(loadingImage);
  div.appendChild(loadingBox);
  document.body.appendChild(div);
  loadingCount++;
}

/**
 * 隱藏loading框
 *
 * @export
 * @return {*}
 */
export function hideLoading() {
  if (loadingCount <= 0) return;
  loadingCount--;
  if (loadingCount === 0) {
    document.body.removeChild(div);
  }
}

export let isAllLoadingClose = loadingCount <= 0;
