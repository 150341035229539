import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from "vue-router";
import store from "@/store";
import { loginAction } from "@/utils/login";

import { getQueryString } from "@/utils/utils";

let defaultPath = "/pointShop";
if (getQueryString("liff.state")) {
  const url = decodeURIComponent(getQueryString("liff.state"));
  console.log(url, process.env.VUE_APP_SUB_PATH);
  defaultPath = url.replace(process.env.VUE_APP_SUB_PATH, "/");
}
if (getQueryString("path")) {
  defaultPath = getQueryString("path");
}

console.log("defaultPath: " + defaultPath);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: "/",
    name: "home",
    redirect: defaultPath,
    meta: {
      guest: true
    }
  },
  {
    path: "/login",
    name: "loginPage",
    component: () => import("@/views/loginPage"),
    meta: {
      guest: true
    }
  },
  {
    path: "/register",
    name: "registerPage",
    component: () => import("@/views/registerPage")
  },
  {
    path: "/pointShop",
    name: "pointShop",
    component: () => import("@/views/pointShop"),
    meta: {
      // title: "點數商城",
      // showPoint: true
      showSort: true,
      keepAlive: true,
      guest: true,
      title: "麥當勞 點數商城"
    }
  },
  {
    path: "/search",
    name: "searchPage",
    component: () => import("@/views/searchPage"),
    meta: {
      // title: "點數商城",
      // showPoint: true
      guest: true
    }
  },
  {
    path: "/pointShop/:id",
    name: "pointShopDetail",
    component: () => import("@/views/pointShopDetail"),
    meta: {
      showPoint: true,
      guest: true,
      backTo: "pointShop",
      title: "麥當勞 點數商城"
    }
  },
  {
    path: "/announcement/:id",
    name: "announcementPage",
    component: () => import("@/views/announcementPage"),
    meta: {
      title: "注意事項"
    }
  },
  {
    path: "/exchange/:id",
    name: "exchangePage",
    component: () => import("@/views/exchangePage"),
    meta: {
      title: "麥當勞 點數商城",
      keepAlive: true,
      notAddToChain: true
    }
  },
  {
    path: "/instruction/:type",
    name: "instructionPage",
    component: () => import("@/views/instructionPage"),
    meta: {
      title: ""
    }
  },
  {
    path: "/pick",
    name: "pickPage",
    component: () => import("@/views/pickPage"),
    meta: {
      title: "麥當勞 隨買店取",
      showMyTicket: true,
      keepAlive: true,
      guest: true
    }
  },
  {
    path: "/pick/:id",
    name: "pickDetail",
    component: () => import("@/views/pickDetail"),
    meta: {
      guest: false,
      title: "麥當勞 隨買店取"
    }
  },
  {
    path: "/pickexchange/:id",
    name: "pickExchangePage",
    component: () => import("@/views/pickExchangePage"),
    meta: {
      keepAlive: true,
      title: "麥當勞 隨買店取"
    }
  },
  {
    path: "/transpwd",
    name: "enterTransPwd",
    component: () => import("@/views/enterTransPwd"),
    meta: {
      notAddToChain: true
    }
  },
  {
    path: "/forgettranpwd",
    name: "forgetTranPwd",
    component: () => import("@/views/forgetTranPwd"),
    meta: {}
  },
  {
    path: "/myticket",
    name: "myTicket",
    component: () => import("@/views/myTicket"),
    meta: {
      hideNavbar: false,
      keepAlive: false,
      showOrderManagement: true,
      title: "麥當勞 電子商品券"
    }
  },
  {
    path: "/myticket/:id",
    name: "ticketDetail",
    component: () => import("@/views/ticketDetail"),
    meta: {
      backTo: "myTicket",
      notAddToChain: true,
      title: "麥當勞 電子商品券"
    }
  },
  {
    path: "/order",
    name: "orderPage",
    component: () => import("@/views/orderPage"),
    meta: {
      showSort: true,
      title: "麥當勞 訂單管理"
    }
  },
  {
    path: "/order/:id",
    name: "orderDetail",
    component: () => import("@/views/orderDetail"),
    meta: {
      title: "麥當勞 訂單管理"
    }
  },
  {
    path: "/order/:id/refund",
    name: "refundDetail",
    component: () => import("@/views/refundDetail"),
    meta: {
      title: "麥當勞-訂單退貨"
    }
  },
  {
    path: "/paymentResult",
    name: "paymentResult",
    component: () => import("@/views/paymentResult"),
    meta: {
      canNotBackToHere: true,
      guest: true,
      notAddToChain: true,
      title: "麥當勞 訂單購買"
    }
  },
  {
    path: "/paymentRelay",
    name: "paymentRelay",
    component: () => import("@/views/paymentRelay"),
    meta: {
      guest: true
    }
  },
  {
    path: "/waiting",
    name: "waitingPage",
    component: () => import("@/views/waitingPage"),
    meta: {
      hideBack: true,
      notAddToChain: true
    }
  },
  {
    path: "/test-ui",
    name: "testUI",
    component: () => import("@/views/testUI")
  },
  {
    path: "/testLinePay",
    name: "TestPage",
    component: () => import("@/views/testLinePay"),
    meta: {
      guest: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_SUB_PATH),
  routes
});

router.beforeEach(async (to, from, next) => {
  const routeLength = store.state.route.routeChain.length;
  console.log(to.path, from.path, routeLength, to.meta.guest);
  if (!to.meta.guest) {
    try {
      await loginAction();
    } catch (error) {
      console.error("[ERROR: cdf632] error", "src/router/index.js", error);
    }
  }
  // if (from.meta.canNotBackToHere) {
  //   next();
  // }
  // if (!routeLength && to.meta.backTo) {
  //   return next();
  // }
  if (routeLength === 0) {
    // store.dispatch("route/setRouteIndexPop", "fade");
    if (
      to.path.toLowerCase() === from.path.toLowerCase() ||
      from.path.toLowerCase() === "/"
    ) {
      console.log(to);
      if (!to.meta.backTo) {
        store.dispatch("route/addRouteChain", to);
      }
    } else {
      if (!from.meta.backTo) {
        store.dispatch("route/addRouteChain", from);
      }
      store.dispatch("route/addRouteChain", to);
    }
  } else if (routeLength === 1) {
    // store.dispatch("route/setRouteIndexPop", "slide-left");
    store.dispatch("route/addRouteChain", to);
  } else {
    const lastBeforeRoute = store.state.route.routeChain[routeLength - 2];
    if (lastBeforeRoute.path.toLowerCase() === to.path.toLowerCase()) {
      store.dispatch("route/popRouteChain");
      // store.dispatch("route/setRouteIndexPop", "slide-right");
    } else {
      store.dispatch("route/addRouteChain", to);
      // store.dispatch("route/setRouteIndexPop", "slide-left");
    }
  }
  next();
});

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router;
