import { list } from "@/apis/card";
import { addCommas } from "@/utils/utils";
import { getInfo } from "@/apis/member";
import request from "@/utils/request";
const user = {
  namespaced: true,
  state: {
    bonus: 0,
    bonusText: "0",
    userinfo: null,
    tokenKey: "token"
  },
  mutations: {
    SET_BONUS(state, data) {
      state.bonus = data;
    },
    SET_BONUSTEXT(state, data) {
      state.bonusText = data;
    },
    SET_USERINFO(state, data) {
      state.userinfo = data;
    },
    SET_TOKENKEY(state, data) {
      state.tokenKey = data;
    }
  },
  actions: {
    setBonus({ commit, state }, data) {
      if (!state.userinfo) return;
      list().then((res) => {
        if (res.length) {
          const { bonus } = res.find((item) => item.isDefault);
          commit("SET_BONUS", bonus);
          commit("SET_BONUSTEXT", addCommas(bonus));
        }
      });
    },
    async getUserInfo({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem(state.tokenKey)) {
          getInfo().then((res) => {
            commit("SET_USERINFO", res);
            dispatch("setBonus");
            resolve();
          });
        } else {
          reject("無token");
        }
      });
    },
    setTokenKey({ commit }, data) {
      commit("SET_TOKENKEY", data);
      request.defaults.headers.accessToken = localStorage.getItem(data);
    }
  },
  getters: {
    hasSetTransPwd: (state) => {
      return !!state.userinfo?.bindTransPwd;
    },
    phone: (state) => {
      return state.userinfo?.phone;
    }
  }
};

export default user;
