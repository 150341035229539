import axios from "axios";
import store from "../store";
import { hideLoading, showLoading } from "./loading";
import JSONbig from "json-bigint";
import { showDialog } from "vant";
const JSONbigToString = JSONbig({ storeAsString: true });
import getDeviceInfo from "@/utils/deviceInfo";
import packageConfig from "../../package.json";
import router from "@/router";
import { v4 as uuidv4 } from "uuid";
import liff from "@line/liff";
import { refreshToken } from "@/apis/member";
import { useRoute } from "vue-router";
import CryptoJS from "crypto-js";
import { loginAction } from "@/utils/login";
import { getUrlPathWithoutSubDir } from "@/utils/utils";

const route = useRoute();

let sourceTokenList = {};

let refreshQueue = []; //存儲需要重發的接口隊列

let tokenRefreshing = false;

let isDebug = localStorage.getItem("debug") == "true";

const baseUrl = () => {
  const env = localStorage.getItem("currentEnv");
  if (
    env &&
    (import.meta.env.MODE == "qa-java" || import.meta.env.MODE == "development")
  ) {
    if (env == "new") {
      return process.env.VUE_APP_BASE_API;
    } else {
      return process.env.VUE_APP_BASE_API_OLD;
    }
  } else {
    return process.env.VUE_APP_BASE_API;
  }
};

const accessToken = localStorage.getItem(store?.state?.user?.tokenKey) || "";
const { osName, deviceUUID } = getDeviceInfo();
const service = axios.create({
  baseURL: baseUrl(),
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    version: packageConfig.version,
    deviceUUID: deviceUUID,
    deviceType: osName,
    appVersion: packageConfig.version,
    accessToken,
    ignoreEncryptionFilter:
      process.env.NODE_ENV === "development" ||
      import.meta.env.MODE === "qa-java"
        ? true
        : false,
    lineAccessToken: getAccessToken()
  },
  transformResponse: [
    function (data) {
      // 解決雪花算法id Long類型導致的失精問題
      try {
        return JSONbigToString.parse(data);
      } catch (e) {
        return data;
      }
    }
  ]
});

// 請求攔截器
service.interceptors.request.use(
  (config) => {
    showLoading();
    const cToken = axios.CancelToken.source();
    config.cancelToken = cToken.token;
    const cUuid = uuidv4();
    config.uuid = cUuid;
    config.headers.lineAccessToken = getAccessToken();
    sourceTokenList[cUuid] = { ...cToken, url: config.url };
    console.log(config.data);
    if (config.data && config.headers.ignoreEncryptionFilter) {
      let configData;
      if (config.data instanceof Object) {
        configData = config.data;
      } else {
        if (!config.headers.mcdS1) {
          configData = JSON.parse(config.data);
        }
      }
      if (!config.headers.mcdS1) {
        config.data = {
          ...configData
        };
      }
    } else if (config.data) {
      const data = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        store.getters["keystone/aesKey"],
        {
          iv: store.getters["keystone/aesIv"],
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );

      config.data = data.toString();
    }
    if (config.showErrMsg == null || config.showErrMsg == undefined) {
      config.showErrMsg = true;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

let try10299 = localStorage.getItem("try10299") == "true"; // token過期實驗
let try10005 = localStorage.getItem("try10005") == "true";
// 響應攔截器
service.interceptors.response.use(
  async (response) => {
    setTimeout(() => {
      hideLoading();
    }, response.config.minLoadingTime || 100);
    let { code } = response.data;
    const { msg, data } = response.data;
    console.log(response.config);
    if (try10299) {
      code = 10299;
    }
    if (try10005 && response.config.url == "/common/invoiceOrg") {
      code = 10005;
    }

    if (code != 0) {
      if (
        response.config.showErrMsg &&
        code != 10299 &&
        code != 612001 &&
        code != 621007 &&
        code != 621008 &&
        code !== 10005 &&
        code !== 614001
      ) {
        showDialog({
          message: msg,
          confirmButtonColor: "#EF0000",
          confirmButtonText: "確定"
        }).then(() => {
          response.config.onConfirmClick && response.config.onConfirmClick();
        });
      }

      if (code == 10005) {
        //收到10005時取消所有xhr請求並清除token
        try10005 = false;
        localStorage.setItem("try10005", false);
        if (localStorage.getItem(store.state.user.tokenKey)) {
          localStorage.removeItem(store.state.user.tokenKey);
        }
        await loginAction();
        location.reload();
        return Promise.resolve();
      } else if (code === 614001) {
        if (localStorage.getItem(store.state.user.tokenKey)) {
          localStorage.removeItem(store.state.user.tokenKey);
        }
        await loginAction();
        window.reload();
        return Promise.resolve();
      }
      console.log(router.currentRoute.value);
      if (code == 612001 || code == 621007) {
        // 未綁定
        localStorage.removeItem(store.state.user.tokenKey);
        console.log();
        setTimeout(() => {
          location.href =
            process.env.VUE_APP_COOLBE_LIFF_URL +
            "/member-bind?redirect-uri=" +
            encodeURIComponent(
              "https://liff.line.me/" +
                process.env.VUE_APP_LIFF_ID +
                getUrlPathWithoutSubDir(
                  location.pathname + location.search,
                  process.env.VUE_APP_SUB_PATH
                )
            );
        }, 100);
      }

      if (code == 621008) {
        // 精誠id不一致
        localStorage.removeItem(store.state.user.tokenKey);
        await loginAction();
        router.go(0);
      }

      if (tokenRefreshing && !response.config._retry) {
        console.log("無感刷新token中");
        return new Promise((resolve, reject) => {
          refreshQueue.push({ response, resolve, reject });
        });
      }

      if (code == 10299) {
        // 10299需要無感刷新token,並且把後面所有請求添加入重發隊列
        tokenRefreshing = true;
        // cancelAllRequest();
        response.config._retry = true;
        try10299 = false;
        localStorage.setItem("try10299", false);
        // localStorage.removeItem(store.state.user.tokenKey);
        refreshToken().then((res) => {
          tokenRefreshing = false;

          axios.defaults.headers.accessToken = res.token;
          localStorage.setItem(store.state.user.tokenKey, res.token);
          replyAllRequests();
          return service(response.config);
        });
      }
      return Promise.reject(response.data);
    }
    delete sourceTokenList[response.config.uuid];
    if (!data) {
      return data;
    }
    let decodeData = data;
    if (!response.config.headers.ignoreEncryptionFilter) {
      const decode = CryptoJS.AES.decrypt(
        data,
        store.getters["keystone/aesKey"],
        {
          iv: store.getters["keystone/aesIv"],
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      decodeData = JSONbigToString.parse(
        decode.toString(CryptoJS.enc.Utf8) || "{}"
      );
    }
    console.log(response.config.url, JSON.stringify(decodeData));
    return decodeData;
  },
  (err) => {
    console.log(err);
    setTimeout(() => {
      hideLoading();
    }, 100);
  }
);

function cancelAllRequest() {
  console.log("cancel list", sourceTokenList);
  for (const k in sourceTokenList) {
    sourceTokenList[k].cancel("cancel");
  }
  sourceTokenList = {};
}

function getAccessToken() {
  try {
    return liff.getAccessToken();
  } catch (e) {
    return null;
  }
}

function replyAllRequests() {
  console.log("需要重發請求的列表", refreshQueue);
  refreshQueue.forEach((item) => {
    const { response, resolve, reject } = item;
    console.log("正在重發", response.config.url);
    service(response.config)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
  refreshQueue = [];
}

export default service;
