<template lang="pug">
.error-brower(v-if="!isLine")
  p 若點擊按鈕無反應，請協助自行手動切換返回LINE APP
  commonBtn(text="打開LINE程式", @click="goLine")
router-view(v-else, v-slot="{ Component }")
  Suspense
    div
      navBar
      transition(:name="transf")
        div
          keep-alive
            component(
              v-if="route.meta.keepAlive",
              :is="Component",
              :key="route.name"
            )
          component(v-if="!route.meta.keepAlive", :is="Component")
</template>

<script setup>
import { computed, ref } from "vue";
import navBar from "@/components/navBar";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import commonBtn from "@/components/commonBtn";
const store = useStore();
const route = useRoute();

const transf = computed(() => store.state.route.pageDirection);
var u = navigator.userAgent,
  isLineApp = u.indexOf("Line") > -1;
// dev環境跳過line判斷
console.log("current env:", import.meta.env.MODE);
const whiteList = ["paymentRelay", "paymentResult"];
const isWhiteList = computed(() => {
  return whiteList.some((item) => location.pathname.includes(item));
});
const isDebug = localStorage.getItem("debug") === "true";

let isLine = ref(
  // import.meta.env.MODE === "development" ||
  //   import.meta.env.MODE === "qa" ||
  //   import.meta.env.MODE === "qa-java" ||
  true || isLineApp || isWhiteList.value || isDebug
);

const goLine = () => {
  location.href =
    "https://liff.line.me/" + process.env.VUE_APP_LIFF_ID + route.fullPath;
};
console.log("是否line", isLine.value);
</script>

<style lang="scss">
@import "@/assets/css/global.scss";
html {
  background: #eeeeee;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  box-sizing: border-box;
  position: relative;
}

#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  max-width: 750px;
  margin: 0 auto;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.error-brower {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  p {
    vertical-align: middle;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.child-view {
  position: absolute;
  transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}
</style>
