<template lang="pug">
.header(v-if="!route.meta.hideNavbar")
  .left
    span.back(@click="back", v-if="canBack")
    // h3.title(
    //  v-if="route.meta.title",
    //  :style="{ textAlign: route.meta.showPoint ? 'left' : 'center', transform: !route.meta.showPoint && canBack ? 'translateX(-28px)' : 'none' }"
    // ) {{ route.meta.title }}

    img.logo(v-else, :src="icon == 'normal' ? normalIcon : greenIcon")
  .right(v-if="route.meta.showPoint && store.state.user.userinfo")
    .point {{ store.state.user.bonusText }}
  .right(v-if="route.meta.showMyTicket")
    a.my-ticket(@click="router.push({ name: 'myTicket' })") 我的票夾
  .right(v-if="route.meta.showOrderManagement")
    a.my-ticket(@click="router.push({ name: 'orderPage' })") 訂單管理
  .right(v-if="route.meta.showSort")
    .sort(@click="showSort")

  van-popup(
    position="bottom",
    v-model:show="showSortPopup",
    teleport="body",
    round,
    :closeable="true",
    :close-icon="closeImg"
  )
    .popup-container
      h3 {{ sortTitle }}
      radioGroup(v-model:value="sortValue", @change="sort")
        inputComponent.radio(
          v-for="(item, index) in radioList",
          :key="index",
          type="radio",
          name="sort",
          :value="item.key"
        ) {{ item.name }}
        //- inputComponent.radio(type="radio", name="sort", value="1") 點數高到低
        //- inputComponent.radio(type="radio", name="sort", value="2") 最新兌換
</template>

<script setup name="navBar">
import { useRoute, useRouter } from "vue-router";
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import radioGroup from "@/components/radioGroup";
import inputComponent from "@/components/inputComponent";
import bus from "@/utils/eventBus";
import request from "@/utils/request";

const route = useRoute();
const router = useRouter();
const store = useStore();
const canBack = computed(() => {
  return (
    (store.state.route.routeChain.length >= 2 || route.meta.backTo) &&
    !route.meta.hideBack
  );
  // return history.state.back ? true : false;
});
const title = ref("");
const showPoint = ref(false);

const showSortPopup = ref(false);
const closeImg = new URL("../assets/imgs/close.png", import.meta.url).href;
const sortValue = ref("");

const radioList = ref([]);
const showChangeEnv = ref(false);
const env = import.meta.env.MODE;
const currentEnv = ref(localStorage.getItem("currentEnv") || "new");
const hrefTo = ref("pointShop");
const normalIcon = new URL("../assets/imgs/logo.png", import.meta.url).href;
const greenIcon = new URL("../assets/imgs/logo-green.png", import.meta.url)
  .href;
const sortTitle = ref("篩選");

const back = () => {
  if (route.meta.backTo) {
    router.replace({ name: route.meta.backTo });
  } else {
    // router.go(-1);
    router.replace(
      store.state.route.routeChain[store.state.route.routeChain.length - 2]
    );
  }
};

const sort = (e) => {
  console.log(e);
  showSortPopup.value = false;
  bus.emit("sortChange", e);
};

const showSort = () => {
  showSortPopup.value = true;
};

bus.on("updateSortList", (e) => {
  sortValue.value = "";
  radioList.value = e;
});

bus.on("updateSortTitle", (e) => {
  sortTitle.value = e;
});

const icon = ref(currentEnv.value == "new" ? "normal" : "green");
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 65px;
  line-height: 60px;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 999;
  // position: relative;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    .title {
      flex: 1;
    }
    h3 {
      font-size: 17px;
      margin: 0;
    }
    .logo {
      width: 40px;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .back {
      display: block;
      width: 28px;
      height: 28px;
      background: url(@/assets/imgs/back.png) center no-repeat;
      background-size: contain;
      margin-right: 15px;
    }
  }
  .right {
    position: absolute;
    right: 20px;
    .point {
      font-size: 17px;
      font-weight: bold;
      line-height: 24px;
      &::before {
        width: 17px;
        height: 17px;
        display: inline-block;
        content: "";
        margin-right: 5px;
        vertical-align: middle;
        background: url(@/assets/imgs/point.png) center no-repeat;
        background-size: contain;
      }
    }
    .my-ticket {
      // color: $red;
      font-size: $normal;
      font-weight: bold;
    }
    .sort {
      background: url(@/assets/imgs/sort2.png) center no-repeat;
      background-size: contain;
      width: 25px;
      height: 25px;
    }
  }
}

.popup-container {
  padding: 20px;
  h3 {
    text-align: center;
    margin: 0;
  }
  .radio {
    padding: 10px 0;
    border-bottom: 1px solid rgb(241, 241, 241);
  }
}
</style>
