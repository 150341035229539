import { secretKey } from "@/apis/common";
import request from "@/utils/request";
import CryptoJS from "crypto-js";
import { generateRandomString } from "@/utils/utils";
import JSEncrypt from "jsencrypt";
import user from "./user";
const keyStone = {
  namespaced: true,
  state: {
    aesKey: "",
    aesIv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AESIV)
  },
  mutations: {
    SET_PUBLICKEY(state, data) {
      state.publicKey = data;
    },
    SET_RANDOMID(state, data) {
      state.randomId = data;
    },
    SET_AESKEY(state, data) {
      state.aesKey = data;
    },
    SET_AESIV(state, data) {
      state.aesIv = data;
    }
  },
  actions: {
    setPublicKeyAndRandomId({ state, commit }, { lineId }) {
      let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AESIV);
      if (localStorage.getItem("aes")) {
        const decode = CryptoJS.enc.Base64.parse(localStorage.getItem("aes"));
        const aesObj = JSON.parse(decode.toString(CryptoJS.enc.Utf8));
        request.defaults.headers.randomId = aesObj.randomId;
        request.defaults.headers.mcdS1 = aesObj.rsaAesKey;
        commit("SET_AESKEY", aesObj.key);
        commit("SET_AESIV", aesObj.iv);
        return Promise.resolve("loaded from cache");
      }
      return secretKey({ lineId }).then(({ publicKey, randomId }) => {
        const accessToken = localStorage.getItem(user.state.tokenKey) || "";
        const aesKey = accessToken
          ? CryptoJS.MD5(accessToken).toString().substring(0, 6) +
            generateRandomString(18)
          : generateRandomString(24);
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const rsaAesKey = encrypt.encrypt(aesKey);
        request.defaults.headers.randomId = randomId;
        request.defaults.headers.mcdS1 = rsaAesKey;

        let key = CryptoJS.enc.Utf8.parse(aesKey);

        const saveRsaKey = {
          randomId,
          rsaAesKey,
          key,
          iv
        };
        const encodeSaveRsaKey = CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(JSON.stringify(saveRsaKey))
        );
        localStorage.setItem("aes", encodeSaveRsaKey);
        commit("SET_AESKEY", key);
        commit("SET_AESIV", iv);
      });
    },
    setAesKey({ commit }, data) {
      commit("SET_AESKEY", CryptoJS.enc.Utf8.parse(data));
    }
  },
  getters: {
    aesKey: (state) => {
      return state.aesKey;
    },
    aesIv: (state) => {
      return state.aesIv;
    }
  }
};

export default keyStone;
