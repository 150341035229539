export function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const result = window.location.search.substring(1).match(reg);
  if (result != null) {
    return decodeURIComponent(result[2]);
  }
  return null;
}

export function randomString(len) {
  len = len || 32;
  var $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxPos = $chars.length;
  var pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export function addCommas(num) {
  num = num.toString();

  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function secondsToMinutesSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const minutesText = String(minutes).padStart(2, "0");
  const secondsText = String(remainingSeconds).padStart(2, "0");
  return minutesText + ":" + secondsText;
}

let func = null;
let controller = null;
let signal = null;

export function addScrollToBottom(cb, dom) {
  controller = new AbortController();
  signal = controller.signal;
  window.addEventListener("scroll", (func = () => scrollToBottom(cb, dom)), {
    capture: true,
    signal
  });
}
export function removeScrollToBottom() {
  controller?.abort();
  // window.removeEventListener("scroll", func, true);
}

function scrollToBottom(cb, dom) {
  var scr =
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    dom.getBoundingClientRect().bottom;
  var clientHeight = document.documentElement.clientHeight;
  var scrHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight;

  if (scr <= scrHeight + 50) {
    if (cb && typeof cb === "function") {
      cb();
    }
  }
}

export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}

export function getUrlPathWithoutSubDir(path, subDir) {
  if (subDir == "/") {
    return path;
  }
  const reg = new RegExp(`^${subDir}`, "ig");
  return path.replace(reg, "");
}
export function isNeedLiff() {
  const path = location.pathname;
  if (["/paymentResult", "/paymentRelay"].includes(path)) {
    return false;
  }
  return true;
}

export function isMobile() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
      userAgent
    );

  return isMobile;
}
