import { getDeviceInfo } from "@/utils/deviceInfo";
const system = {
  namespaced: true,
  state: {
    osVersion: "3.5.0",
    deviceType: "",
    deviceUUID: "59e9669aa5f66473",
    deviceToken: "123",
    modelId: "iPhone 11"
  },
  mutations: {
    SET_OSVERSION: (state, data) => {
      state.osVersion = data;
    },
    SET_DEVICETYPE: (state, data) => {
      state.deviceType = data;
    },
    SET_DEVICEUUID: (state, data) => {
      state.deviceUUID = data;
    }
  },
  getters: {},
  actions: {
    setOsVersion({ commit, state }, data) {
      commit("SET_OSVERSION", getDeviceInfo().osVersion);
    },
    setDeviceType({ commit, state }, data) {
      commit("SET_DEVICETYPE", getDeviceInfo().osName);
    },
    setDeviceUUID({ commit }) {
      commit("SET_DEVICEUUID", getDeviceInfo().deviceUUID);
    }
  }
};

export default system;
