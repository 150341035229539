const route = {
  namespaced: true,
  state: {
    routeIndex: 0,
    pageDirection: "fade",
    routeChain: []
  },
  mutations: {
    SET_ROUTEINDEX(state, data) {
      state.routeIndex = data;
    },
    SET_PAGEDIRECTION(state, data) {
      state.pageDirection = data;
    }
  },
  actions: {
    setRouteIndex({ commit, state }) {
      commit("SET_ROUTEINDEX", state.routeIndex + 1);
    },
    setRouteIndexPop({ commit, state }) {
      commit("SET_ROUTEINDEX", state.routeIndex - 2);
    },
    setPageDirection({ commit }, data) {
      commit("SET_PAGEDIRECTION", data);
    },
    addRouteChain({ state }, route) {
      let finded = state.routeChain.find((item) => item.name == route.name);
      if (!route.meta.notAddToChain && !finded) {
        state.routeChain.push(route);
      }
    },
    popRouteChain({ state }) {
      state.routeChain.pop();
    }
  },
  getters: {}
};

export default route;
