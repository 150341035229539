"use strict";
function _OS(name, identifier) {
  this.name = name;
  this.identifier = identifier;
}
_OS.prototype.getVersion = () => "";

// Apple device 苹果设备
const getAppleVersion = (ua) => {
  const [versionInfo] = ua.match(/ [0-9]+(_|.)?[0-9]*(_|.)?[0-9]*/gi);
  const osVersion = versionInfo?.replace(/_/g, ".")?.trim();
  return osVersion || "";
};

// iOS
const iOS = new _OS("iOS", ["iPhone", "iPhone OS"]);
iOS.getVersion = getAppleVersion;
// iPad
const iPadOS = new _OS("iPad", ["iPad"]);
iPadOS.getVersion = getAppleVersion;
// MacOS
const MacOS = new _OS("Mac OS", ["Macintosh", "Mac OS"]);
MacOS.getVersion = getAppleVersion;

// Windows PC
const windowsOS = [
  {
    name: "10",
    identifier: ["10", "10.0"]
  },
  {
    name: "7",
    identifier: ["6.1"]
  },
  {
    name: "8",
    identifier: ["6.3"]
  },
  {
    name: "XP",
    identifier: ["5.1"]
  }
];
const Windows = new _OS("Windows", ["Windows"]);
Windows.getVersion = (ua) => {
  const [versionInfo] = ua.match(/ ?Windows NT [0-9]+.?[0-9]*/g);
  const osVersion = versionInfo?.replace(/Windows NT/g, "")?.trim();
  const matchVersion = windowsOS.find(({ identifier }) =>
    identifier.some((id) => id === osVersion)
  )?.["name"];
  return matchVersion || "";
};

const Android = new _OS("Android", ["Android"]);
Android.getVersion = (ua) => {
  const [versionInfo] = ua.match(/ ?Android [0-9]+.?[0-9]*/g);
  const osVersion = versionInfo?.replace(/Android/g, "")?.trim();
  return osVersion || "";
};

const Linux = new _OS("Linux", ["Linux"]);

const systems = [iOS, iPadOS, MacOS, Windows, Android, Linux];

export { systems as default, iOS, iPadOS, MacOS, Windows, Android, Linux };
