import browsers from "./browsers";
import systems from "./systems";

/**
 * @description 获取操作系统信息
 * @returns {Object}
 */
export const getOSInfo = () => {
  const { userAgent } = navigator;
  const osInfo = systems.find(({ identifier }) =>
    identifier.some((id) => userAgent.includes(id))
  );
  const osVersion = osInfo.getVersion(userAgent);
  return {
    osName: osInfo.name,
    osVersion
  };
};
/**
 * @description 获取浏览器信息
 * @returns {Object}
 */
export const getBrowserInfo = () => {
  const { userAgent } = navigator;
  const browserInfo = browsers.find(({ identifier }) =>
    userAgent.includes(identifier)
  );
  const browserVersion = browserInfo.getVersion(userAgent); // 系统名称

  return {
    browserName: browserInfo.name,
    browserVersion
  };
};

/**
 * @description 判断是否移动设备
 * @returns {Boolean} true: 移动端 & false: PC 端
 */
export const isMobile = () => {
  const isMatch = !!window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return isMatch;
};

/**
 * @description 获取全部设备信息
 * @returns {Object}
 */
export const getDeviceInfo = () => {
  return {
    ...getOSInfo(),
    ...getBrowserInfo(),
    isMobile: isMobile(),
    deviceUUID: getDeviceUUID()
  };
};

function getDeviceUUID() {
  let uuid = localStorage.getItem("deviceUUID");
  if (!uuid) {
    uuid = generateUUID();
    localStorage.setItem("deviceUUID", uuid);
  }
  return uuid;
}

function generateUUID() {
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export { getDeviceInfo as default };
