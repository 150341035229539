import request from "@/utils/request";

/**
 *根據LineToken登錄
 *
 * @export
 * @param {Object} [data={
 *  lineToken 用戶line token
 * }]
 * @return {Promise}
 */
export function login(data = {}) {
  return request.post("/login/LineId", data, {
    headers: {
      // ignoreEncryptionFilter: true
    }
  });
}
