const pointShop = {
  namespaced: true,
  state: {
    pointShopItem: JSON.parse(localStorage.getItem("pointShopItem")) || {},
    passTransPwd: {}
  },
  mutations: {
    SET_POINTSHOPITEM(state, data) {
      state.pointShopItem = data;
    },
    SET_PASSTRANSPWD(state, data) {
      state.passTransPwd = data;
    }
  },
  actions: {
    setPointShopItem({ commit, state }, data) {
      const { pointShopItem } = state;
      pointShopItem[data.couponId || data.id] = data;
      localStorage.setItem("pointShopItem", JSON.stringify(pointShopItem));
      commit("SET_POINTSHOPITEM", pointShopItem);
    },
    setPassTransPwd({ commit, state }, data) {
      const { passTransPwd } = state;
      passTransPwd[data] = true;
      commit("SET_PASSTRANSPWD", passTransPwd);
    },
    removePassTransPwd({ commit, state }, data) {
      const { passTransPwd } = state;
      delete passTransPwd[data];
      commit("SET_PASSTRANSPWD", passTransPwd);
    },
    clearPointShopItem({ commit, state }) {
      localStorage.removeItem("pointShopItem");
      commit("SET_POINTSHOPITEM", {});
    }
  },
  getters: {}
};

export default pointShop;
